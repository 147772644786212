.events_pagination{
	.page-numbers{
		    flex-wrap: wrap;
		    margin-bottom: 0;
		    justify-content: center;
		    display: flex;
		    padding-left: 0;
		    list-style: none;
		    border-radius: .25rem;
		    margin: 0;

		li{
			margin: 0;
			&:not(:last-child) {
				margin-right: 5px !important;
			}


			a, span{
				width: 50px;
			    height: 50px;
			    font-size: 17px;
			    line-height: 22px;
			    letter-spacing: 0.2px;
			    color: #42516d;
			    border: 1px solid #e0e0e0;
			    display: inline-flex;
			    justify-content: center;
			    align-items: center;
			    padding: 0px;
			    border-radius: 50px;
			    margin: 0px;
			    font-weight: bold;
			    text-decoration: none;
			    -moz-transition: all .3s ease;
			    -webkit-transition: all .3s ease;
			    transition: all .3s ease;
					
				&:focus{
					outline: none;
				}

				&.next{
					margin: 0;
					width: unset;
					text-decoration: none;
					padding: 9px 15px 9px 15px;
				}

				&.prev{
					margin: 0;
					width: unset;
					text-decoration: none;
					padding: 9px 15px 9px 15px;
				}

				&:hover {
					color: #fff;
				}
				
			}
			span{
				color: #fff;
				background-color: red;
			}
		}
	}
}
